import React, { useRef } from "react";
import { Pannellum } from "@clovis818/pannellum-react";

const Panorama = (props) => {

  return (
    <>
      <Pannellum
        autoLoad
        width="100vw"
        height="100vh"
        image={props.link}
        pitch={0}
        yaw={180}
        hfov={130}
        showControls={false}
        // hotspotDebug={true}
        disableKeyboardCtrl={true}
        mouseZoom={false}
      ></Pannellum>
    </>
  );
};

export default Panorama;
