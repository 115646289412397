import "./App.css";
import Panorama from "./components/PanoComponent";

function App() {
  return (
    <div>
      <Panorama link="http://360tour.critecng.com/fotos/alojamento1/1.png" />
    </div>
  );
}

export default App;
